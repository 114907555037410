
@font-face {
    font-family: "darumadrop";
    src: url('../fonts/Darumadrop_One/DarumadropOne-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}
@font-face {
    font-family: "noto_sans";
    src: url('../fonts/Darumadrop_One/DarumadropOne-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}
@font-face {
    font-family: "oxygen-bolt";
    src: url('../fonts/Oxygen/Oxygen-Bold.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}
@font-face {
    font-family: "oxygen-regular";
    src: url('../fonts/Oxygen/Oxygen-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}
@font-face {
    font-family: "oxygen-light";
    src: url('../fonts/Oxygen/Oxygen-Light.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}


@import "./abstracts/variable";
.page_Wrapper {
    padding: 30px;
}
//startseiten styling

.banner-main
 {
    background-image: url(../media/banner/Headergrafik-LY03.png);
    min-height: 400px;
}
@media (min-width: 885px) {
    .banner-main {
        object-fit:contain;
        background-image: url(../images/wald-banner.png);
        min-height: 864px;
    }
}

@import "./images.scss";
@import "~bootstrap/scss/bootstrap";
@import "./links.scss";
@import "./footer.scss";
@import "./yoga.scss";
@import "./kontakt.scss";
@import "./atemtraining.scss";
@import "./workshops.scss";
@import "./navbar.scss";
.krankenkassenzertifiziert{
    margin: 28px 50px 50px 50px;
    max-width: 300px;
    background-color: #09494b7b;
    color: rgb(42, 42, 42);
    box-shadow: 60px -16px teal;
    border: #00fd98;
}
.italic {
    font-style: italic;
}
.table-heads {
    background-color: $base-theme-color;
    padding: 5px 5px;
    border-radius: 3px;
}
.submit_btn {
text-decoration:line-through;
}
//Index Banner


a {
    color: green($color: #00fd98);
}

.intern-jump {
    min-height: 20px;
    color: green;
}

.no-service {
    display: flex;
    background-color: rgb(220, 0, 0);
    min-height: 20px;
    justify-content: center;
    border-radius: 4px;
    margin: 10px;

    .info {
        padding: 5px;
    }
}












